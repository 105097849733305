<template>
	<div>
		<!-- <div class="w-12/12">
            <div class="flex flex-col md:flex-row md:justify-between">
                <div class="m-3 md:w-5/12">
                    <div class="font-light text-2xl tracking-widest border-b-2">
                        <span class="text-3xl mr-3">//</span>{{companyInfo.name}}
                    </div>

                    <div class="md:w-8/12 my-5">
                        <div class="flex justify-between my-2 items-center">
                            <div class="font-bold">
                                Company number:
                            </div>
                            <div class="text-sm">
                                <span v-if="companyInfo.company_number">{{ companyInfo.company_number.toUpperCase() }}</span>
                                <span v-if="!companyInfo.company_number">-</span>
                            </div>
                        </div>
                        <div class="flex justify-between my-2 items-center">
                            <div class="font-bold">
                                Postcode:
                            </div>
                            <div class="text-sm">
                                <span v-if="companyInfo.postcode">
                                    {{ companyInfo.postcode.toUpperCase() }}
                                    <span class="ml-1">(<a class="text-sm underline hover:no-underline text-blue-600" :href="'https://www.google.co.uk/maps/place/' + companyInfo.postcode.toUpperCase()" target="_blank">map</a> <span class="inline-block" v-html="link_icon"></span>)</span>
                                </span>
                                <span v-if="!companyInfo.postcode">-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

		<div class="w-12/12">
			<div class="flex flex-row-reverse xl:w-8/12 mb-3 sm:px-6 lg:px-8">
				<button
					v-if="activeTeamPermission != 'READ'"
					@click="deleteCompanyModal()"
					class="btn btn-danger-outline btn-small h-10 mr-3">
					Delete group
				</button>
			</div>

			<CustomDomainAnalysisView
				:companyInfo="companyInfo"
				:initialDomains="companyDomains"
				:key="companyDomainsKey"
				:teamId="teamId"
				:projectId="projectId"
				:companyId="companyId" />
		</div>

		<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
			<template v-slot:header>
				<h3>Are you sure you want to remove this group?</h3>
			</template>

			<template v-slot:body>
				<div class="my-4">
					Name: <span class="font-bold text-md">{{ companyInfo.name }}</span>
				</div>
				<div class="flex">
					<v-button @click="cancelDeleteCompany" class="btn btn-default mr-3">Cancel</v-button>
					<v-button @click="confirmDeleteCompany" :loading="deletingList" class="btn btn-danger"
						>Remove group</v-button
					>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import CustomDomainAnalysisView from "@/components/projects/company-analysis/CustomDomainAnalysisView";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";

const link_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>`;

export default {
	created() {},

	mounted() {
		this.getCompanyInfo();
	},

	components: {
		// DomainMetricGroupView,
		CustomDomainAnalysisView,
		Modal,
		"v-button": Button,
	},

	props: ["teamId", "projectId", "companyId"],

	data() {
		return {
			link_icon,
			companyInfo: {},
			companyDomains: [],
			companyDomainsKey: 0,
			confirmDeleteModal: false,
			deletingList: false,
		};
	},

	methods: {
		async getCompanyInfo() {
			try {
				let response = await this.$api
					.resource("companyAnalysisProjectCompanyList", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.fetchOne(this.companyId);

				this.companyInfo = response.data;

				this.companyDomains = this.companyInfo.domains;
				this.companyDomainsKey += 1;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async deleteCompany() {
			try {
				let response = await this.$api
					.resource("companyAnalysisProjectCompanyList", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.delete(this.companyId);
				this.$router.push({
					name: "company-analysis-project",
					params: { teamId: this.teamId, projectId: this.projectId },
				});
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		deleteCompanyModal() {
			this.confirmDeleteModal = true;
		},
		cancelDeleteCompany() {
			this.confirmDeleteModal = false;
		},
		async confirmDeleteCompany() {
			this.deletingList = true;
			await this.deleteCompany(this.companyId);
			this.deletingList = false;
			this.confirmDeleteModal = false;
		},
	},

	watch: {},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
