<template>
	<div class="flex justify-between mb-3">
		<v-button @click="back" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to group list</v-button
		>
	</div>
	<div class="heading heading-big">
		{{ companyInfo.name }}
	</div>

	<CompanyView :teamId="teamId" :projectId="projectId" :companyId="companyId" />
</template>

<script>
import Button from "@/components/ui/Button";
import CompanyView from "@/components/projects/company-analysis/CompanyView";
import { mapGetters } from "vuex";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>`;

export default {
	created() {},

	mounted() {
		this.getCompanyInfo(this.companyId);
	},

	components: {
		// Tab,
		// Tabs,
		"v-button": Button,
		CompanyView,
	},

	props: ["teamId", "projectId", "companyId"],

	data() {
		return {
			back_icon,
			companyInfo: { name: "" },
		};
	},

	methods: {
		back() {
			this.$router.push({
				name: "company-analysis-project",
				params: { teamId: this.teamId, projectId: this.projectId },
			});
		},

		async getCompanyInfo(company_id) {
			try {
				let response = await this.$api
					.resource("companyAnalysisProjectCompanyList", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.fetchOne(this.companyId);

				this.companyInfo = response.data;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	watch: {},

	computed: {
		// ...mapGetters([
		//     'activeTeamPermission'
		// ])
	},
};
</script>

<style></style>
